import { Ticket } from "@/model/api/Ticket";
import { activeStatuses, TicketStatusEnum } from "@/model/enums/TicketStatusEnum";
import { TicketsRoutesEnum } from "@/modules/tickets/router";
import { ticketsService } from "@services/tickets.service";
import moment from "moment";
import { Options, Prop, Vue } from "vue-property-decorator";

import {
  MomentZoneCalendar,
  TicketDriverData,
  TicketHistory,
  FieldsetSkeleton, ContractAutocomplete,
} from "@components";

import TicketFinishStatus
  from '@/components/TicketFinishStatus/TicketFinishStatus.vue';
import TicketStatus
  from "@/components/TicketStatus/TicketStatus.vue";
import ZoneCard from
  "@/components/ZoneCard/ZoneCard.vue";

import TicketDisputeResolverDialog
  from "@/components/TicketDisputeResolverDialog/TicketDisputeResolverDialog.vue";
import { ShiftsRoutesEnum } from "@/modules/shifts/router";
import { Contract } from "@/model/api/Contract";
import { PaymentTypeEnum } from "@/model/enums/PaymentTypeEnum";
import { PaymentStatusEnum } from "@/model/enums/PaymentStatusEnum";

@Options({
  name: 'TicketPage',

  components: {
    MomentZoneCalendar,
    TicketDisputeResolverDialog,
    TicketDriverData,
    TicketHistory,
    ContractAutocomplete,
    TicketFinishStatus,
    TicketStatus,
    ZoneCard,
    FieldsetSkeleton
  }
})
export default class TicketData extends Vue {

  @Prop() readonly ticketId!: String;

  @Prop() readonly ticket!: Ticket;


  get isNew() {
    return this.ticketId === 'new';
  }

  get backRoute() {
    return { name: TicketsRoutesEnum.TICKETS_LIST }
  }

  get shiftRoute() {
    return ShiftsRoutesEnum.SHIFTS_DETAIL;
  }

  get paymentStatusOptions() {
    return [
      { label: this.$t('ticket.payment_statuses.PENDING'), value: PaymentStatusEnum.PENDING },
      { label: this.$t('ticket.payment_statuses.AUTHORIZED'), value: PaymentStatusEnum.AUTHORIZED },
      { label: this.$t('ticket.payment_statuses.PAID'), value: PaymentStatusEnum.PAID },
    ]
  }

  get duration() {
    const startMoment = moment(this.ticket.datetime_start);
    let d = null;

    if (!this.ticket.datetime_end.isValid()) {
      d = moment.duration(moment().diff(startMoment));
    } else {
      const endMoment = moment(this.ticket.datetime_end);
      d = moment.duration(endMoment.diff(startMoment));
    }

    const h = Math.floor(d.asHours());
    const m = Math.floor(d.minutes());

    const htext = this.$t('hours', h).trim();
    const mtext = this.$t('minutes', m).trim();

    return `${h} ${htext}` + (m ? ` ${m} ${mtext}` : '');
  }

  get hasEndDate() {
    return this.ticket?.datetime_end?.isValid();
  }

  get isClosed() {
    return [
      TicketStatusEnum.DELETED_BY_SYSTEM,
      TicketStatusEnum.DELETED_BY_USER,
      TicketStatusEnum.CLOSED,
    ].includes(this.ticket.status);
  }

  get isBtnSaveTicketDataVisible() {
    return this.isClosed || [TicketStatusEnum.ACCEPTED_BY_USER].includes(this.ticket.status);
  }

  get showActions() {
    return activeStatuses.includes(this.ticket?.status);
  }
  get canDelete() {
    return this.ticket?.status === TicketStatusEnum.ACCEPTED_BY_USER;
  }

  get isDispute() {
    return this.ticket?.status === TicketStatusEnum.DISPUTE;
  }

  get canSeeTotalTon() {
    return this.ticket.shift.payment_type === PaymentTypeEnum.TON;
  }

  get hiddenHistoryColumns() {
    const columnHeaders = {}
    if (this.ticket.shift.payment_type !== PaymentTypeEnum.TON && !this.ticket.shift.is_ton_requested) {
      columnHeaders['ton_image_path_url'] = true
      columnHeaders['ton'] = true
    }
    return columnHeaders;
  }

  get isGoingToLoad() {
    return [
      TicketStatusEnum.DUMPED,
      // TicketStatusEnum.WAITING_LOAD,
    ].includes(this.ticket?.status);
  }

  get isGoingToDump() {
    return [
      TicketStatusEnum.LOADED,
      // TicketStatusEnum.WAITING_DUMP,
    ].includes(this.ticket?.status);
  }

  calcInProgress: boolean = false;

  onContractSelect(contract: Contract) {
    if (contract && contract.id) {
      this.ticket.contract_id = contract?.id;
    } else {
      delete this.ticket.contract_id
    }
  }

  async calcFee() {
    try {
      this.calcInProgress = true;
      const response = await ticketsService.calcFee(this.ticket);
      this.ticket.fee = response.fee;
    } catch (error: any) {
      if (error?.status === 422) {
        const fieldsInError = Object.keys(error.data.errors);

        fieldsInError.forEach(k => {
          this.$errorMessage(error.data.errors[k][0], k.toUpperCase());
        });

      }
    } finally {
      this.calcInProgress = false;
    }
  }

  onResolved() {
    this.displayResolverDialog = false;
    this.$emit('update:visible', false);

    this.$successMessage(
      this.isDispute
        ? this.$t("ticket.messages.dispute_resolved")
        : this.$t("ticket.messages.close_success")
    );

    this.$emit('update');
  }

  onResolveCancel() {
    this.displayResolverDialog = false;
  }

  deleteTicket() {
    this.$confirmMessage(
      this.$t('ticket.messages.delete_confirm', { item: 'Ticket' })
    ).then(r => {
      if (r) {
        this.$waitFor(async () => {
          await ticketsService.deleteBySystem(this.ticket);
          this.$emit('deleted');
        })
      }
    });
  }


  async updatePayment() {
    try {
      this.requestPending = true;

      await ticketsService.updatePatch({
        id: this.ticket.id,
        payment_status: this.ticket.payment_status,
      });

      this.$successMessage(this.$t('ticket.messages.update_success'));
    } catch (error) {
      this.$errorMessage(this.$t('ticket.messages.update_fail'));
    } finally {
      this.requestPending = false;
    }
  }

  async update() {
    if (!this.ticket.contract_id) {
      this.$errorMessage("Contract is required")
      return;
    }

    try {
      this.requestPending = true;

      await ticketsService.updatePatch({
        id: this.ticket.id,
        datetime_start: this.ticket.datetime_start,
        datetime_end: this.ticket.datetime_end,
        total_ton: this.ticket.total_ton || null,
        contract_id: this.ticket.contract_id,
        cycles: this.ticket.cycles,
        fee: this.ticket.fee,
      });

      this.$successMessage(this.$t('ticket.messages.update_success'));
    } catch (error) {
      this.$errorMessage(this.$t('ticket.messages.update_fail'));
    } finally {
      this.requestPending = false;
    }
  }

  displayResolverDialog: boolean = false;
  dialogHeader: string = null;

  closeTicket() {
    this.dialogHeader = "Close ticket - Refund";
    this.displayResolverDialog = true;
  }

  disputeTicket() {
    this.dialogHeader = "Resolve dispute - Refund";
    this.displayResolverDialog = true;
  }

  onConfirm() {
    this.displayResolverDialog = false;

  }
  onCancel() {
    this.displayResolverDialog = false;
  }

}