import { Ticket } from "@/model/api/Ticket";
import { TicketsRoutesEnum } from "@/modules/tickets/router";
import { ticketsService } from "@services/tickets.service";
import { Options, Prop, Vue } from "vue-property-decorator";

import {
  FieldsetSkeleton,
} from "@components";

import TicketStatus
  from "@/components/TicketStatus/TicketStatus.vue";
import moment from "moment";
import {fileDownload} from "@/utils/utils";
import {FilterMatchMode} from "primevue/api";

@Options({
  name: 'TicketPage',

  components: {
    FieldsetSkeleton,
    TicketStatus
  }
})
export default class TicketPage extends Vue {
  @Prop()
  readonly ticketId!: string;

  ticket: Ticket = null;

  exporting: boolean = false;

  get tabs() {
    let i = 0;

    return [
      {
        label: this.$t('ticket.tabs.data'),
        icon: 'fas fa-clipboard-list',
        to: { name: TicketsRoutesEnum.TICKETS_DETAIL },
        class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
      },
      {
        label: this.$t('ticket.tabs.tiket_history'),
        icon: 'fas fa-road',
        to: { name: TicketsRoutesEnum.TICKETS_DETAIL_HISTORY },
        class: this.$route.name === this.tabs?.[i++].to.name ? 'p-highlight' : ''
      },
    ];
  }

  get isNew() {
    return this.ticketId === 'new';
  }

  get backRoute() {
    return { name: TicketsRoutesEnum.TICKETS_LIST }
  }

  get exports(){
    return [
      {
        label: 'Excel',
        command: () => {
          this.onExport('excel')
        }
      },
      {
        label: 'PDF',
        command: () => {
          this.onExport('pdf')
        }
      }
    ]
  }

  async onExport(type: string) {
    try {
      const filters = {id: {
        value: this.ticket.id,
        matchMode: FilterMatchMode.EQUALS
      }};

      this.exporting = true

      const response = await ticketsService.exportFile(filters, type);

      this.exporting = false

      const fileName = `TicketDetails_Export_${moment().format('YYYY_MM_DD')}`;

      fileDownload(
          fileName,
          response,
          type === 'excel' ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "application/pdf"
      )
    } catch (error) {
      console.error(error);
      this.exporting = false
      this.$errorMessage("Export failed");
    }
  }

  refresh() {
    this._loadTicket();
  }

  async update() {
    try {
      this.requestPending = true;

      await ticketsService.updatePatch({
        id             : this.ticket.id,
        datetime_start : this.ticket.datetime_start,
        datetime_end   : this.ticket.datetime_end,
        cycles         : this.ticket.cycles,
        fee            : this.ticket.fee,
      });

      this.$successMessage("Ticket updated successfully");
    } catch (error) {
      this.$errorMessage("Ticket update stopped.");
    } finally {
      this.requestPending = false;
    }
  }

  private _loadTicket() {
    return this.$waitFor(async () => {
      this.ticket = await ticketsService.getById(+this.ticketId);
    }, "Loading ticket failed");
  }

  async created() {
    if (this.isNew) {
      this.ticket = new Ticket();
    } else {
      await this._loadTicket();
    }
  }

}