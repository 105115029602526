import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6e3eee2c")
const _hoisted_1 = { class: "map_controller" }
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = { class: "status_buttons" }
const _hoisted_4 = { class: "p-button-label" }
const _hoisted_5 = { class: "time" }
const _hoisted_6 = {
  ref: "status_bar",
  class: "status_bar"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "load" }
const _hoisted_9 = {
  key: 0,
  class: "pi pi-arrow-up"
}
const _hoisted_10 = { class: "dump" }
const _hoisted_11 = {
  key: 0,
  class: "pi pi-arrow-down"
}
const _hoisted_12 = { class: "map" }
const _hoisted_13 = {
  id: "w3_map",
  ref: "mapEl",
  class: "p-mt-3"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableSkeleton = _resolveComponent("TableSkeleton")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Slider = _resolveComponent("Slider")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (!_ctx.tracking && _ctx.requestPending)
    ? (_openBlock(), _createBlock(_component_TableSkeleton, {
        key: 0,
        columns: 3,
        rows: 5
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createElementVNode("section", _hoisted_1, [
          _createElementVNode("header", _hoisted_2, [
            _createVNode(_component_Button, {
              icon: "pi pi-refresh",
              class: "p-mr-3",
              onClick: _ctx.refresh
            }, null, 8, ["onClick"]),
            _createElementVNode("h5", null, "Selected moment: " + _toDisplayString(_ctx.selectedTime), 1)
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliderSteps, (step, index) => {
              return (_openBlock(), _createBlock(_component_Button, {
                onClick: ($event: any) => (_ctx.changeStep(step.index)),
                class: _normalizeClass(`p-button-sm status_${_ctx.TicketStatusEnum[step.status].toLowerCase()}${step.selected ? ' focused' : ''}`),
                key: `tracking_${index}`
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: _normalizeClass(`pi ${step.status === 110 ? 'pi pi-arrow-up' : 'pi pi-arrow-down'} p-button-icon p-button-icon-left`)
                  }, null, 2),
                  _createElementVNode("span", _hoisted_4, [
                    _createTextVNode(_toDisplayString(_ctx.getStatusLabel(step)) + " ", 1),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(step.time), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["onClick", "class"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliderSteps, (step, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: `tracking_${index}`,
                style: _normalizeStyle({ left: step.position + 'px' }),
                onClick: ($event: any) => (_ctx.setSlider(step.status, step.index))
              }, [
                _createElementVNode("div", _hoisted_8, [
                  (step.status === 110)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  (step.status === 130)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11))
                    : _createCommentVNode("", true)
                ])
              ], 12, _hoisted_7)), [
                [
                  _directive_tooltip,
                  _ctx.$t(`ticket.statuses.${_ctx.TicketStatusEnum[step.status]}`),
                  void 0,
                  { top: true }
                ]
              ])
            }), 128))
          ], 512),
          _createVNode(_component_Slider, {
            modelValue: _ctx.sliderIndex,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.sliderIndex = $event)),
            min: 0,
            max: _ctx.maxSlider,
            disabled: !_ctx.maxSlider
          }, null, 8, ["modelValue", "max", "disabled"])
        ]),
        _createElementVNode("section", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, null, 512)
        ])
      ], 64))
}