import { Tracking } from '@/model/api/Traking';
import { CRUD } from './base/crud';

class TrackingService extends CRUD<Tracking, any> {
    readonly endPoint = `tracking`;

    public indexAll(args: any) {        
        const params      = this.getCleanParams(args);
        const cancelToken = this.generateCancelToken();

        console.debug("params", params);

        return this.get<Tracking[]>(
            `${this.endPoint}`, 
            { params, cancelToken }
        );
    }
}

export const trackingService = new TrackingService();



