import { markRaw, toRaw }        from "vue";
import { Options, Vue }          from "vue-class-component";
import { Prop, Watch }           from "vue-property-decorator";

import moment                    from "moment";
import timezone                  from "moment-timezone";

import { FilterMatchMode }       from "primevue/api";

import { ticketsService }        from "@services/tickets.service";
import { trackingService }       from "@services/traking.service";

import {TicketStatusEnum} from "@/model/enums/TicketStatusEnum";
import { Ticket, TicketHistory } from "@/model/api/Ticket";
import { Tracking }              from "@/model/api/Traking";

import {
  TableSkeleton, 
  TicketStatus
} from "@components";

@Options({
  inheritAttrs: false, 
  components: {
    TableSkeleton,
    TicketStatus
  },

  setup() {
    const shape: google.maps.Polygon = markRaw(null);

    return {
      shape
    }
  }
})
export default class TicketHistoryMap extends Vue {
  @Prop() readonly ticketId! : number;
  @Prop() readonly ticket!   : Ticket;
  // @Prop() readonly timeZone! : string;

  get timeZone(): string {
    return this.ticket?.zone_name;
  }

  sliderIndex: number = 0;
  sliderSteps: any[] = [];
  deactivatedTab: boolean;

  @Watch('sliderIndex')
  onSliderIdexChange(value, old) {
    const oldStepButton = this.sliderSteps.find((step) => step.index === old)
    const newStepButton = this.sliderSteps.find((step) => step.index === value)
    if(oldStepButton){
      oldStepButton.selected = false
    }
    if(newStepButton){
      newStepButton.selected = true
    }
    this._initMarker();
  }

  private moveMarker() {
    const point = this.tracking?.[this.sliderIndex]

    if(!point) { return;  }

    const {lat, lng} = point; 
    this.marker.setPosition(new google.maps.LatLng({ lat, lng }));
  }

  private centerMapOnMarker() {
    this.map.setZoom(12);
    this.map.setCenter(this.marker.getPosition());
  }

  get selectedTime() {
    if (this.sliderIndex === null) return "";
    const date = this.tracking?.[this.sliderIndex]?.created_at; 
    return timezone.tz(date, this.timeZone).format("YYYY/MM/DD hh:mm A");
  }

  get maxSlider() {
    return (this.tracking?.length - 1) || 0; 
  }
  
  get TicketStatusEnum() {
    return TicketStatusEnum;
  }

  isValidStep(status: number): boolean {
    return status === TicketStatusEnum.LOADED || status === TicketStatusEnum.DUMPED
  }

  setSlider(status: number, stepIndex: number) {
    this.sliderIndex = stepIndex;
  }
  
  mapRef: google.maps.Map     = null;

  marker: google.maps.Marker  = null;

  get map() {
    return toRaw(this.mapRef);
  }

  get mapElement() {
    return (this.$refs.mapEl as HTMLElement)
  }

  getDurationFrom(idx: number, th: TicketHistory){
    if ( this.history.length < idx+2) return; 

    const startMoment = moment(th.created_at)
    const endMoment   = moment(this.history[idx+1].created_at);

    const d = moment.duration(endMoment.diff(startMoment));

    const h = Math.floor(d.asHours());
    const m = Math.floor(d.minutes());
    const s = Math.floor(d.seconds());

    return [h,m,s].map(x => x.toString().padStart(2, '0')).join(':');
  }

  timeDiffNext(idx: number, th: TicketHistory){
    const format = "hh:mm A";

    let result = timezone.tz(th.created_at, this.timeZone).format(format);

    if (this.history.length > idx+1){
      const endMoment   = timezone.tz(
        this.history[idx+1].created_at, 
        this.timeZone
      )?.format(format);

      result += ` - ${endMoment}`;
    }

    return result;
  }

  timeZoneDate(date: string) {
    return timezone
      .tz(date, this.timeZone)
      .format("YYYY-MM-DD hh:mm A");
  }

  activated() {
    if(
        this.deactivatedTab &&
        this.history &&
        this.tracking
    ){
      this._buildMap(this.mapElement);
      this._drawTraking();
      this._initMarker();
      this.setSteps(this.statusBarWidth())
    }
  }

  deactivated() {
    this.deactivatedTab = !this.history || !this.tracking
  }

  private _buildMap(element: HTMLElement, bounds?: google.maps.LatLngBounds) {
    this.mapRef = new google.maps.Map(
      element,
      {
        center: this.$config.startCenter,
        zoom: this.$config.startZoom,
      },

    );

    if (bounds) {
      this.map.fitBounds(bounds, 0);
    }

    this.map.data.setStyle({
      strokeWeight  : 1,
      strokeOpacity : 1,
      strokeColor   : "#3399FF",
      fillColor     : "#3399FF",
      fillOpacity   : 0.2,
      editable      : false,
      draggable     : false,
      clickable     : true,
      zIndex        : 1,
    });
  }

  private _initShape() {
    (this as any).shape = new google.maps.Polygon({
      strokeColor   : "00ffb7",
      strokeOpacity : 0.8,
      strokeWeight  : 2,
      fillColor     : "00ffb7",
      fillOpacity   : 0.35,
      editable      : true,
      draggable     : true
    })
  }
  
  // private _initMarker() { 
  //   this.marker = new google.maps.Marker({
  //     draggable: true
  //   });

  //   this.marker.addListener('click', () => {
  //     this.map.setZoom(20);
  //     this.map.setCenter(this.marker.getPosition());
  //   });

  //   this.marker.addListener('dragend', () => {
  //     const latLng = this.marker.getPosition();
  //     const lat = latLng.lat();
  //     const lng = latLng.lng();

  //     this.zone.lat = lat;
  //     this.zone.lng = lng;

  //     api.convertTo3wa({lat, lng}).then( r => {
  //       this.zone.w3 = r.words;
  //     })
  //   });
  // }

  async refresh() {
    await this._loadhistory();
    await this._loadTracking();

    this.setSteps(this.statusBarWidth())
  }

  private getDimensions(){
    setTimeout(() => {
      this.setSteps(this.statusBarWidth())
    }, 500)
  }

  statusBarWidth(): number{
    return (this.$refs.status_bar as HTMLInputElement).clientWidth
  }

  @Watch('tracking')
  onTrackingChange(newValue){
    if(newValue){
      this.$nextTick(() => {
        this.setSteps(this.statusBarWidth())
      })
    }
  }

  async mounted(){
    window.addEventListener('resize', this.getDimensions);
  }

  async unmounted(){
    window.removeEventListener('resize', this.getDimensions);
  }


  private _initMarker() {
    this.marker ??= new google.maps.Marker();

    this.moveMarker();

    if (!this.marker?.getMap()) {
      this.marker.setMap(this.map);
    }
  }

  getStatusLabel(step){
    if(step.status === TicketStatusEnum.DUMPED && step.is_free_dumped){
      return this.$t(`ticket.statuses.FREE_DUMPED`)
    }
    return this.$t(`ticket.statuses.${TicketStatusEnum[step.status]}`)
  }

  private _drawTraking() {
    const bounds = new google.maps.LatLngBounds();
    
    this.tracking.forEach((x) => {
      bounds.extend(x)
    });

    this.map.fitBounds(bounds);

    this.map.data.add({
      geometry: new google.maps.Data.LineString(
        this.tracking.map(x => ({lat: x.lat, lng: x.lng}))
      ),
    });

  }

  
  tracking: Tracking[] = null;
  private _loadTracking() {
    return this.$waitFor(async () => {
      this.tracking = await trackingService.indexAll({
        filters: {
          ticket_id: { 
            value: this.ticketId, 
            matchMode: FilterMatchMode.EQUALS
          }
        }
      });
    })
  }

  history: TicketHistory[] = null;
  private _loadhistory() {
    return this.$waitFor(async () => {
      this.history = await ticketsService.history(this.ticketId);
    })
  }

  getClosestStep(sliderIndex: number, next?: boolean | undefined) : any[]{
    return this.sliderSteps.filter((step) => {
      if(next){
        return step.index > this.sliderIndex
      } else {
        return step.index < this.sliderIndex
      }
    })
  }

  changeStep(stepIndex: number){
    this.sliderIndex = stepIndex
  }

  private setSteps(sliderWidth?: number){

    if(this.sliderSteps.length){
      this.sliderSteps.forEach((step) => {
        const mt = this.tracking.length / step.index
        step.position = (sliderWidth / mt).toFixed(2)
      })
    } else {
      let currentStatus: number

      this.tracking.forEach((tracking:Tracking, index) => {
        if(currentStatus !== tracking.status && this.isValidStep(tracking.status) || tracking.is_free_dumped){
          const date = tracking.created_at;
          const mt = this.tracking.length / index
          currentStatus = tracking.status
          this.sliderSteps.push({
            status: tracking.status,
            is_free_dumped: tracking.is_free_dumped,
            id: tracking.id,
            index,
            time: timezone.tz(date, this.timeZone).format("hh:mm A"),
            position: (sliderWidth / mt).toFixed(2)
          })
        }
      })

    }
  }

  async created() {
    if (this.ticketId) {
      await this._loadhistory();
      await this._loadTracking();

      if(document.body.contains(this.mapElement)){
        this._buildMap(this.mapElement);
        this._drawTraking();
        this._initMarker();
      }
    }
  }

}