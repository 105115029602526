import moment from "moment";
import { classToPlain, plainToClass } from "class-transformer";
import { Options, Vue } from "vue-class-component";

import { TicketsRoutesEnum } from "../../router";
import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
import { ticketsService } from "@services/tickets.service";
import { Ticket } from "@/model/api/Ticket";
import { FilterMatchMode } from "primevue/api";

import {
  activeStatuses,
  finishStatuses,
  inactiveStatuses,
  TicketFinishStatusEnum,
  TicketStatusEnum
} from "@/model/enums/TicketStatusEnum";

import {
  AppTable, MaterialSelect, PaymentStatus,
  SendMessageDialog, ShiftAutocomplete,
  TicketFinishStatus,
  TicketStatus,
} from "@components";

import Table from "@components/AppTable/Table";

import TicketDisputeResolverDialog
  from "@/components/TicketDisputeResolverDialog/TicketDisputeResolverDialog.vue";

import TicketsFilters from "./TicketsFilters.vue";
import { TicketFilters } from "./TicketFilters";
import { fileDownload } from "@/utils/utils";
import { ShiftsRoutesEnum } from "@/modules/shifts/router";
import { CompaniesRoutesEnum } from "@/modules/companies/router";

@Options({
  components: {
    AppTable,
    SendMessageDialog,
    ShiftAutocomplete,
    TicketFinishStatus,
    TicketStatus,
    PaymentStatus,
    MaterialSelect,
    TicketDisputeResolverDialog,
    TicketsFilters,
  },

  beforeRouteEnter(to, from, next) {
    const linked = [
      TicketsRoutesEnum.TICKET_PREFIX,
      ShiftsRoutesEnum.SHIFTS_DETAIL
    ];

    /*
      Keep table state and filters if navigation comes from Ticket context, 
      or from Shift Detail 
    */
    if (from.name && linked.every(x => !(from.name as string).startsWith(x))) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_TICKETS);
      sessionStorage.removeItem(SessionStorageEnum.TICKETS_ADVANCED_FILTERS);
      sessionStorage.removeItem('ticketsOnlyActive')
    }

    next();
  },

  beforeRouteLeave() {
    if (this.service?.cancelPendingRequests) {
      this.service.cancelPendingRequests();
    }
  },
})
export default class TicketsPage extends Vue {

  filters: any = null;

  exporting: boolean = false;

  onlyActive: boolean = true;

  selectedTicket: Ticket = null;

  displayResolverDialog: boolean = false;

  displayMessageDialog: boolean = false;

  advFilters: TicketFilters = new TicketFilters();

  get exports() {
    return [
      {
        label: 'Excel',
        command: () => {
          this.onExport('excel')
        }
      },
      {
        label: 'PDF',
        command: () => {
          this.onExport('pdf')
        }
      },
      {
        label: 'Invoice',
        command: () => {
          this.onExport('invoice')
        }
      }
    ]
  }

  get service() {
    return ticketsService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_TICKETS;
  }

  get detailRoute() {
    return TicketsRoutesEnum.TICKETS_DETAIL;
  }

  get selectedTicketId() {
    return this.selectedTicket?.id;
  }

  get costCodesOptions() {
    return []
  }

  get statusOptions() {
    const statuses = [...activeStatuses];

    if (!this.onlyActive) {
      statuses.push(...inactiveStatuses);
    }

    return statuses.map(s => ({
      label: this.$t(`ticket.statuses.${TicketStatusEnum[s]}`),
      value: s
    }))
  }

  get finishStatusOptions() {
    return finishStatuses.map(s => ({
      label: this.$t(`ticket.finish_status.${TicketFinishStatusEnum[s]}`),
      value: s
    }))
  }

  get shiftRoute() {
    return ShiftsRoutesEnum.SHIFTS_DETAIL;
  }

  get customerDetailRoute() {
    return CompaniesRoutesEnum.CUSTOMER_DETAIL;
  }

  get supplierDetailRoute() {
    return CompaniesRoutesEnum.SUPPLIER_DETAIL;
  }

  getDuration(ticket: Ticket) {
    const startMoment = moment(ticket.datetime_start);
    let d = null;

    if (!ticket.datetime_end.isValid()) {
      d = moment.duration(moment().diff(startMoment));
    } else {
      const endMoment = moment(ticket.datetime_end);
      d = moment.duration(endMoment.diff(startMoment));
    }

    const h = Math.floor(d.asHours());
    const m = Math.floor(d.minutes());
    const s = Math.floor(d.seconds());

    // const htext = this.$t('hours', h).trim();
    // const mtext = this.$t('minutes', m).trim();

    // return `${h} ${htext}` + (m ? ` ${m} ${mtext}` : '');
    return [h, m, s].map(x => x.toString().padStart(2, '0')).join(':');
  }

  canDelete(t: Ticket) {
    return [TicketStatusEnum.ACCEPTED_BY_USER, TicketStatusEnum.DELETED_BY_USER, TicketStatusEnum.DELETED_BY_SYSTEM].includes(t.status);
  }

  isDispute(t: Ticket) {
    return t?.status === TicketStatusEnum.DISPUTE;
  }

  rowClass(ticket: Ticket) {
    return ticket.status == TicketStatusEnum.DELETED_BY_USER ? 'row-deleted-by-user' : null;
  }

  async onExport(type: string) {
    try {
      const filters = (this.$refs.table as Table).filters;

      this.exporting = true

      const response = await ticketsService.exportFile(filters, type);

      this.exporting = false

      const fileName = type === 'invoice' ? 'invoice' : `TicketList_Export_${moment().format('YYYY_MM_DD')}`;

      fileDownload(
        fileName,
        response,
        type === 'excel' || type === 'invoice' ?
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :
          "application/pdf"
      )

      this.refreshTable();

    } catch (error) {
      console.error(error);
      this.exporting = false
      this.$errorMessage("Export failed");
    }
  }

  onResolve(t: Ticket) {
    this.selectedTicket = plainToClass(Ticket, classToPlain(t));
    this.displayResolverDialog = true;
  }

  onResolved() {
    this.displayResolverDialog = false;
    this.selectedTicket = null;

    this.$successMessage("Ticket dispute resolved");
  }

  onResolveCancel() {
    this.displayResolverDialog = false;
    this.selectedTicket = null;
  }

  onUpdateOnlyActive(val: boolean) {

    sessionStorage.setItem('ticketsOnlyActive', val.toString());

    const table = (this.$refs.table as Table);

    table.filters.status = {
      value: val ? activeStatuses : null,
      matchMode: FilterMatchMode.IN
    }

    table.onApplyFilter();
  }

  onEdit(ticket: Ticket) {
    this.$router.push({
      name: TicketsRoutesEnum.TICKETS_DETAIL,
      params: {
        ticketId: ticket.id
      }
    })
  }

  onDeleted() {
    this.selectedTicket = null;
    this.refreshTable();
    this.$successMessage("Ticket deleted successfully");
  }

  async onDelete(ticket: Ticket) {
    const response = await this.$confirmMessage(
      this.$t('ticket.messages.delete_confirm', { item: 'Ticket' })
    );
    if (response) {
      this.deleteTicket(ticket);
    }
  }

  private deleteTicket(ticket: Ticket) {
    this.$waitFor(
      async () => {
        if (
            ticket.status == TicketStatusEnum.DELETED_BY_USER ||
            ticket.status == TicketStatusEnum.DELETED_BY_SYSTEM
        ) {
          await ticketsService.deleteStandard(ticket);
        } else {
          await ticketsService.deleteBySystem(ticket);
        }
        this.refreshTable();
        this.$successMessage("Ticket successfully deleted")
      },
      "Deleting Ticket failed"
    )
  }

  private refreshTable() {
    const table = (this.$refs.table as Table);
    table.applyFilter();
  }

  goToDetail(ticket: Ticket) {
    this.$router.push({
      name: TicketsRoutesEnum.TICKETS_DETAIL,
      params: { ticketId: ticket.id }
    })
  }

  onFiltersChange() {
    this._updateFilters();
  }

  onReset() {
    this.onlyActive = true;

    this.advFilters = new TicketFilters();
    sessionStorage.removeItem(SessionStorageEnum.TICKETS_ADVANCED_FILTERS);

    this._updateFilters();
  }

  private _updateFilters() {
    const table = (this.$refs.table as Table);

    if (table?.filters) {
      const filters = table.filters;

      if (this.advFilters.from && this.advFilters.to) {
        const from = moment(this.advFilters.from).format("YYYY-MM-DD");
        const to = moment(this.advFilters.to).format("YYYY-MM-DD");

        filters.ticket_day.value = [from, to];

        filters.ticket_day.matchMode
          = FilterMatchMode.BETWEEN;
      } else {
        const from = moment(this.advFilters.from).format("YYYY-MM-DD");

        filters.ticket_day.value
          = from;

        filters.ticket_day.matchMode
          = FilterMatchMode.GREATER_THAN_OR_EQUAL_TO;
      }

      // Filtro per Utente 
      if (filters.user_id) filters.user_id.value = this.advFilters?.user?.id;

      // Filtro per Shift
      if (filters['shift.code']) filters['shift.code'].value = this.advFilters?.shift;

      // Filtro per Zone Load
      if (filters.zone_load_id) filters.zone_load_id.value = this.advFilters?.zone_load?.id;

      // Filtro per Dump Site
      if (filters.zone_dump_id) filters.zone_dump_id.value = this.advFilters?.zone_dump?.id;

      // Filtro per Contract
      if (filters.contract_id) filters.contract_id.value = this.advFilters?.contract?.id;

      // Filtro per Customer
      if (filters.customer_company_id) filters.customer_company_id.value = this.advFilters?.customer_company?.id;

      // Filtro per Supplier
      if (filters.supplier_company_id) filters.supplier_company_id.value = this.advFilters?.supplier_company?.id;
    }

    this._updateTable();
  }

  private _updateTable() {
    const table = (this.$refs.table as Table);
    table.applyFilter();
  }

  private initFilter() {
    const { from, to } = this.advFilters;

    const isValidRange = from && to;

    this.filters = {

      'shift.code': {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },

      status: {
        value: [
          TicketStatusEnum.DISPUTE,
          TicketStatusEnum.CLOSED
        ],
        matchMode: FilterMatchMode.IN
      },

      cost_code: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },

      'shift.job_site': {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH
      },

      material_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      finish_status: {
        value: null,
        matchMode: FilterMatchMode.IN
      },

      ticket_day: {
        value: isValidRange ? [from, to] : from,
        matchMode: isValidRange
          ? FilterMatchMode.BETWEEN
          : FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
      },

      user_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      zone_load_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      zone_dump_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      contract_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      customer_company_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },

      supplier_company_id: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      }
    };
  }

  created() {
    const fromStorage = sessionStorage.getItem('ticketsOnlyActive')

    if (fromStorage) {
      this.onlyActive = JSON.parse(
        sessionStorage.getItem('ticketsOnlyActive')
      );
    }

    this.initFilter();
  }
}