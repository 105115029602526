import { Type } from "class-transformer";

import moment from "moment";

import { Contract } from "@/model/api/Contract";
import { Shift }    from "@/model/api/Shift";
import { User }     from "@/model/api/User";
import {Zone} from "@/model/api/Zone";
import { Company } from "@/model/api/Company";

export class TicketFilters {
    @Type(() => Date)
    from_to: Date[];

    get from() {
        const from = this.from_to?.[0];
        if (from) {
            from.setHours(0);
            from.setMinutes(0);
            from.setSeconds(0);
            return from;
        }

        return null;
    }

    get to() {
        const to = this.from_to?.[1];
        if (to) {
            to.setHours(23);
            to.setMinutes(59);
            to.setSeconds(59);
            return to;
        }

        return null;
    }

    user: User;

    shift: Shift;

    zone_load: Zone;

    zone_dump: Zone;

    contract: Contract;

    customer_company: Company;
    supplier_company: Company;

    constructor() {
        const from = moment();

        this.from_to = [from.toDate()];
    }
}